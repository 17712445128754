@use '@/styles/config/_mixins.scss' as *;

$gap: 24px;

.section {
  margin-top: 55px;
  padding-top: 45px;
  padding-bottom: 65px;
  background-image: linear-gradient(90deg, #1D77B8 0%, #2852A1 100%);

  .title {
    width: 100%;
    max-width: 873px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #FFFFFF;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $gap;
  }

  .col {
    width: col-width(33.333%, 3, $gap);
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 338.52px;
    max-width: 100%;
    min-height: 68px;
    border-radius: 34px;
    background-color: #89c440;
    color: #202425;
    font-family: Mulish;
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: .3px;

  }

  @include mid-break  {
    margin-top: 60px;
    padding-top: 50px;

    .title {
      margin-bottom: 30px;
      max-width: 800px;
      font-size: 26px;
    }

    .row {
      flex-direction: column;
      gap: 17px;
      width: 100%;
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }

    .col {
      width: 100%;
    }
  }


  @include extra-small-break {
    padding-top: 23px;
    padding-bottom: 55px;

    .title {
      font-size: 20px;
      line-height: 1.4;
    }
  }
}

.review {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 14px;
  padding: 28px 20px;

  .reviewHeader {
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .authorImage {
    position: relative;
    width: 80px;
    height: 80px;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .reviewAuthor {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.667;
    letter-spacing: 0.3px;
    color: #1D77B8;
  }

  .reviewTitle {
    margin-top: 14px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 2;
    letter-spacing: 0.3px;
    color: #1D77B8;
  }

  .quote {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.444;
    letter-spacing: 0.3px;
    color: #293737;
  }

  .stars {
    display: flex;
    justify-content: center;
    margin-top: auto;

    svg {
      color: #8AC345;
    }
  }

  @include mid-break  {
    .reviewHeader {
      padding-left: 10px;
      padding-right: 10px;
    }

    .quote {
      padding-bottom: 20px;
    }
  }

  @include extra-small-break {
    padding-bottom: 20px;

    .reviewAuthor, .reviewTitle {
      font-size: 18px;
      line-height: 2;
    }

    .quote {
      padding-bottom: 12px;
      font-size: 16px;
      line-height: 1.625;
    }
  }
}