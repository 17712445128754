@use '@/styles/config/_mixins.scss' as *;

.highlightWrapper {
  text-decoration: none;
}

.label {
  color: #ba5915;
  text-decoration: underline;
}

.highlightNode {
  position: relative;
  display: inline-flex;
  will-change: color;

  &::before {
    pointer-events: none;
    will-change: width; // animating 'height' ftl, transform: translate3d requires overflow: hidden but there is no good way to achieve horizontal padding without visible overflow
    content: "";
    position: absolute;
    width: calc(100% + 0.33em);
    height: 0;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    background-color: var(--highlight-default-bg-color);
    transition: height cubic-bezier(0.4, 0, 0.2, 1) 500ms;
    transition-delay: 250ms;
  }

}

.danger {
  .highlightNode:before {
    background-color: var(--highlight-danger-bg-color);
  }

  .doHighlight {
    .label {
      color: var(--highlight-danger-text-color);
    }
  }
}