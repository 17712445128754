@use '@/styles/config/_mixins.scss' as *;

.section {
  padding-top: 30px;
  padding-bottom: 5px;

  .row {
    margin-left: -7px;
    margin-right: -7px;
    display: flex;
    flex-wrap: wrap;

    &.left {
      flex-direction: row-reverse;
    }
  }

  .col {
    padding-left: 7px;
    padding-right: 7px;

    &.big {
      width: 53.61%;
    }

    &.small {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46.39%;

      > div {
        img, video {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        :global(.react-player__shadow) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .title {
    margin-bottom: 12px;
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 2.375rem;
    line-height: 1.32;
    letter-spacing: 0.1px;
    color: #1D77B8;

    strong {
      color: #8ac345;
    }
  }

  .description {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.31;
    color: #1D77B8;

    strong {
      color: #8AC345;
    }
  }

  .buttonWrapper {
    margin-top: 26px;
  }

  .button {
    display: inline-block;
    min-width: 231px;
    background-color: #1D77B8;
    padding: 20px;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;

    &:hover {
      background-color: #105f94;
    }

    &.mobile {
      display: none;
    }
  }

  video {
    width: 100%;
  }

  .marked {
    color: #8AC345;
  }

  @media (max-width: 1191px) and (min-width: 992px) {
    .title {
      font-size: 1.5rem;
    }

    .description {
      font-size: 1.25rem;
    }
  }

  @include small-break {
    margin-bottom: 17px;

    .col {
      padding: 0;
      flex-direction: column;

      &.big {
        width: 100%;
      }

      &.small {
        max-width: 72vw;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 12px;
      }
    }

    .title {
      text-align: center;
    }

    .description {
      text-align: center;
    }

    .buttonWrapper {
      display: none;
    }

    video {
      max-width: 681px;
      max-height: unset;
    }

    .button {
      display: inline-block;

      &.mobile {
        display: inline-block;
      }
    }
  }

  @include mobile-break {
    padding-top: 19px;

    .title {
      font-size: 1.875rem;
    }

    .description {
      font-size: 1.625rem;
      line-height: 1.54;
    }

    .button {
      min-width: 240px;
      margin-top: 38px;
      padding: 16px;
      border-radius: 8px;
      font-size: 1.25rem;
    }
  }
}